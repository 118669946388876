const theTourForm = () => {
  if (document.querySelector(".tour-btn") != null) {
    const btns = [...document.querySelectorAll(".tour-btn")];
    const close = document.querySelector(".book__container > svg");
    const tourForm = document.querySelector(".book");

    btns.forEach(btn => {
      btn.addEventListener("click", () => {
        tourForm.classList.add("open");
        document.documentElement.classList.add("no-scroll");
        document.body.classList.add("no-scroll");
      });
    });

    close.addEventListener("click", () => {
      tourForm.classList.remove("open");
      document.documentElement.classList.remove("no-scroll");
      document.body.classList.remove("no-scroll");
    });
  }
};

export { theTourForm };
