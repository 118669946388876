import { gsap } from "gsap";

const theIntro = () => {
  const tuk1 = document.querySelector("#Tuk-Tuk-1");
  const tuk2 = document.querySelector("#Tuk-Tuk-2");
  const omaha = document.querySelector("#omaha");
  const copyRows = [...document.querySelectorAll(".the-intro__row.copy")];
  const introContainer = document.querySelector(".the-intro");

  let tl = gsap.timeline({
    delay: 0.2,
    onComplete: () => {
      introContainer.classList.add("gone");
    }
  });

  tl.fromTo(
    tuk1,
    { xPercent: -200, autoAlpha: 0, skewX: 30 },
    { xPercent: 0, autoAlpha: 1, skewX: 0, duration: 0.9, ease: "expo.out" },
    0
  )
    .fromTo(
      tuk2,
      { xPercent: 200, autoAlpha: 0, skewX: -30 },
      { xPercent: 0, autoAlpha: 1, skewX: 0, duration: 0.9, ease: "expo.out" },
      0
    )
    .fromTo(
      omaha,
      { yPercent: 100, autoAlpha: 0 },
      { yPercent: 0, autoAlpha: 1, duration: 0.9, ease: "expo.out" },
      0
    )
    .to(
      tuk1,
      {
        xPercent: -200,
        autoAlpha: 0,
        skewX: 30,
        duration: 0.6,
        ease: "expo.in"
      },
      1.4
    )
    .to(
      tuk2,
      {
        xPercent: 200,
        autoAlpha: 0,
        skewX: -30,
        duration: 0.6,
        ease: "expo.in"
      },
      1.4
    )
    .to(
      omaha,
      {
        yPercent: 100,
        autoAlpha: 0,
        duration: 0.6,
        ease: "expo.in"
      },
      1.4
    )
    .fromTo(
      copyRows,
      {
        yPercent: 100,
        autoAlpha: 0
      },
      {
        yPercent: 0,
        autoAlpha: 1,
        stagger: 0.1,
        duration: 0.9,
        ease: "expo.out"
      },
      2.1
    )
    .to(copyRows, {
      yPercent: -100,
      autoAlpha: 0,
      stagger: 0.1,
      duration: 0.9,
      ease: "expo.in"
    });
};

export { theIntro };
