console.log("Hi");
import Swup from "swup";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupScrollPlugin from "@swup/scroll-plugin";
import SwupBodyClassPlugin from "@swup/body-class-plugin";
import { header } from "./header.js";
import { theTourForm } from "./tours";
import { theIntro } from "./theIntro";
// import { pulsating } from "./advertising";

const menuItems = [...document.querySelectorAll(".menu__item, .header__logo")];

const options = {
  containers: ["#swup"],
  animateHistoryBrowsing: true,
  plugins: [
    new SwupPreloadPlugin(),
    new SwupScrollPlugin({
      doScrollingRightAway: true,
      animateScroll: true,
      scrollFriction: 0.3,
      scrollAcceleration: 0.04
    }),
    new SwupBodyClassPlugin()
  ]
};

const swup = new Swup(options);

const datInit = () => {
  theTourForm();
};

const run = () => {
  theIntro();
  header();
  datInit();
};

swup.on("contentReplaced", () => {
  datInit();
  menuItems.forEach(item => {
    // 1. remove active class from all links
    item.classList.remove("current-menu-item");
    // 2. go through all links and add active class if href == current URL
    if (window.location.href == item.getAttribute("href")) {
      item.classList.add("current-menu-item");
    }
  });
  gtag("config", "UA-153520378-1");
});

// in case the document is already rendered
if (document.readyState != "loading") run();
// modern browsers
else if (document.addEventListener)
  document.addEventListener("DOMContentLoaded", run);
// IE <= 8
else
  document.attachEvent("onreadystatechange", function() {
    if (document.readyState == "complete") run();
  });
