import { gsap } from "gsap";

export function header() {
  class Header {
    constructor() {
      this.DOM = {
        menu: document.querySelector("nav.menu"),
        header: document.querySelector(".header")
      };
      this.DOM.menuLinks = [...this.DOM.menu.querySelectorAll(".menu__item")];
      this.DOM.openBtn = this.DOM.header.querySelector(".burger");
      this.DOM.closeBtn = this.DOM.menu.querySelector(".menu__close");
      this.initEvents();
    }
    initEvents() {
      if (window.innerWidth < 750) {
        this.toggleMenu();
      }
      this.activeMenuItem();
      //   this.scrollEvents();
    }
    toggleMenu() {
      const tl = gsap.timeline({
        paused: true,
        reversed: true,
        onStart: () => {
          document.documentElement.classList.add("no-scroll");
          document.body.classList.add("no-scroll");
        },
        onReverseComplete: () => {
          document.documentElement.classList.remove("no-scroll");
          document.body.classList.remove("no-scroll");
        }
      });

      tl.to(this.DOM.menu, {
        opacity: 1,
        duration: 0.4,
        ease: "power4.out",
        className: "+=menu open"
      }).fromTo(
        this.DOM.menuLinks,
        {
          y: 32,
          opacity: 0
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.9,
          ease: "expo.out",
          stagger: 0.1
        },
        "-=0.2"
      );

      this.DOM.openBtn.addEventListener("click", () => {
        tl.play();
      });
      this.DOM.closeBtn.addEventListener("click", () => {
        tl.reverse();
      });
      this.DOM.menuLinks.forEach(link => {
        link.addEventListener("click", () => {
          tl.reverse();
        });
      });
    }
    scrollEvents() {
      let scrolling = false;
      const header = document.querySelector(".header");

      window.addEventListener("scroll", function() {
        let currentPos = window.scrollY;

        if (!scrolling) {
          window.requestAnimationFrame(function() {
            currentPos >= 200
              ? header.classList.add("slide")
              : header.classList.remove("slide");

            scrolling = false;
          });
          scrolling = true;
        }
      });
    }
    activeMenuItem() {
      this.DOM.menuLinks.forEach(item => {
        // 1. remove active class from all links
        item.classList.remove("current-menu-item");
        // 2. go through all links and add active class if href == current URL
        if (window.location.href == item.getAttribute("href")) {
          item.classList.add("current-menu-item");
        }
      });
    }
  }

  new Header();
}
